export enum ScreenEnum {
  auditor = 'auditor',
  auditorForm = 'auditorForm',
  auditorInfo = 'auditorInfo',
  auditInfoHistory = 'auditInfoHistory',
  auditInfoPending = 'auditInfoPending',
  auditorPending = 'auditorPending',
  auditorReassign = 'auditorReassign',
  auditRequestForm = 'auditRequestForm',
  auditorSummary = 'auditorSummary',
  bill = 'bill',
  billAuditInfo = 'billAuditInfo',
  billContent = 'billContent',
  billHistory = 'billHistory',
  billHistoryDetail = 'billHistoryDetail',
  billPaymentHistory = 'billPaymentHistory',
  billPending = 'billPending',
  billPendingRequest = 'billPendingRequest',
  billRequestForm = 'billRequestForm',
  billSearch = 'billSearch',
  previewAutoRate = 'previewAutoRate',
  previewManualRate = 'previewManualRate',
  previewManualRateFromAuto = 'previewManualRateFromAuto',
};
