import { CommodityClassCd } from "@xpo-ltl/sdk-common";

export const RatingXpoClassData = {
    [CommodityClassCd.CLSS_100]: '100',
    [CommodityClassCd.CLSS_110]: '110',
    [CommodityClassCd.CLSS_125]: '125',
    [CommodityClassCd.CLSS_150]: '150',
    [CommodityClassCd.CLSS_175]: '175',
    [CommodityClassCd.CLSS_200]: '200',
    [CommodityClassCd.CLSS_250]: '250',
    [CommodityClassCd.CLSS_300]: '300',
    [CommodityClassCd.CLSS_350]: '350',
    [CommodityClassCd.CLSS_400]: '400',
    [CommodityClassCd.CLSS_500]: '500',
    [CommodityClassCd.CLSS_50]: '50',
    [CommodityClassCd.CLSS_55]: '55',
    [CommodityClassCd.CLSS_60]: '60',
    [CommodityClassCd.CLSS_65]: '65',
    [CommodityClassCd.CLSS_70]: '70',
    [CommodityClassCd.CLSS_775]: '77.5',
    [CommodityClassCd.CLSS_85]: '85',
    [CommodityClassCd.CLSS_925]: '92.5',
};
