import { Pipe, PipeTransform } from '@angular/core';
import { BillClassTextEnum } from '@shared/enums/common-data/bill-class.enum';
import { BillClassCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'billClass'
})
export class BillClassPipe implements PipeTransform {

  transform(value: BillClassCd): string {
    switch (value) {
      case BillClassCd.NORMAL_MVMT:
        return BillClassTextEnum.normalMvmt;
      case BillClassCd.MASTER_SHPMT:
        return BillClassTextEnum.masterShpmt;
      case BillClassCd.MASTER_SEGMENT:
        return BillClassTextEnum.masterSegment;
      case BillClassCd.SPLIT_SEGMENT:
        return BillClassTextEnum.splitSegment;
      case BillClassCd.PARTIAL_SEGMENT:
        return BillClassTextEnum.partialSegment;
      case BillClassCd.CLAIMS_OVRG_RPTG_BILL:
        return BillClassTextEnum.claimsOvrgRptgBill;
      case BillClassCd.GENERAL_CLAIMS_BUS_SHPMT:
        return BillClassTextEnum.generalClaimsBusShpmt;
      case BillClassCd.ASTRAY_FRT_SEGMENT:
        return BillClassTextEnum.astrayFrtSegment;
      case BillClassCd.CO_BUS_SHPMT:
        return BillClassTextEnum.coBusShpmt;
      case BillClassCd.EXPEDITE:
        return BillClassTextEnum.expedite;
    
      default:
        return '';
    }
  }

}
