import { Injectable } from '@angular/core';
import { RatingLockTariffEnum } from '../../shared.exports';

@Injectable()
export class RatingLockTariffService {
  constructor() {}

  isLockTariff(tariffCode: string): boolean {
    return Object.values(RatingLockTariffEnum).includes(tariffCode as RatingLockTariffEnum);
  }
}
