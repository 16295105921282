<ng-container *ngIf="readonly; else ratingInputField">
  <span [id]="id">{{getReadonlyValue()}}</span>
</ng-container>

<ng-template #ratingInputField>
  <mat-form-field appearance="standard" class="input-rate" [id]="id">
    <input matInput type="text"
      [id]="id"
      [name]="name"
      [disabled]="isFieldDisabled"
      [ngModel]="getValue()"
      (ngModelChange)="setValue($event)"
      (blur)="blur.emit()"
    />
    <mat-error *ngIf="errorMessage">
      {{ errorMessage }}
    </mat-error>
  </mat-form-field>
</ng-template>
