import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RatingRatePipe } from '../../../shared.exports';

@Component({
  selector: 'xpo-rating-input-rate',
  templateUrl: './rating-input-rate.component.html',
  styleUrls: ['./rating-input-rate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RatingRatePipe]
})
export class RatingInputRateComponent {
  @Input() formField: FormControl;
  @Input() minimumChargeInd = false;
  @Input() isFieldDisabled = false;
  @Input() id: string;
  @Input() name: string;
  @Input() errorMessage: string;
  @Input() readonly = false;
  @Output() blur = new EventEmitter<void>();

  constructor(private ratePipe: RatingRatePipe) {}

  getValue(): string {
    return this.ratePipe.transform(this.formField.value);
  }

  getReadonlyValue(): string {
    return this.ratePipe.transform(this.formField.value, { minimumChargeInd: this.minimumChargeInd });
  }

  setValue(newValue: string): void {
    const transformedValue = this.ratePipe.transformToValue(newValue);
    this.formField.setValue(transformedValue);
  }
}
