import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { yesNoOptionArrayValue } from '@shared/data-values/shared/boolean.data';
import { ElementSelectOptionModel } from '@shared/models/shared/select-option.model';

@Component({
  selector: 'app-select-field-yes-no',
  templateUrl: './select-field-yes-no.component.html',
  styleUrls: ['./select-field-yes-no.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldYesNoComponent {

  @Input() formField: FormControl;
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder = 'SELECT';
  @Input() allowEmpty = true;
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() readonly = false;
  @Output() hazmatChange$ = new EventEmitter<boolean>();

  options: ElementSelectOptionModel[] = yesNoOptionArrayValue;

  constructor() { }

  hazmatChange(): void {
    this.hazmatChange$.emit();
  }
}
