import { Injectable } from '@angular/core';
import {
  CorrectionsListMetadataModel,
  CorrectionsListMetadataQueueModel,
  CorrectionsListMetadataReasonCategoriesModel,
  CorrectionsListMetadataReasonDescriptionModel,
  CorrectionsMetadataModel,
} from '@shared/models/corrections/corrections-list-metadata.model';
import { ListCorrectionMetadataQuery, ListCorrectionMetadataResp } from '@xpo-ltl-2.0/sdk-billcorrection';

@Injectable()
export class CorrectionsListMetadataMapperService {
  constructor() {}

  getRequestForListCorrectionsMetaData(): ListCorrectionMetadataQuery {
    return new ListCorrectionMetadataQuery();
  }

  getMappedData(metadataApiResponse: ListCorrectionMetadataResp): CorrectionsListMetadataModel {
    return {
      queues: this.getQueueData(metadataApiResponse),
      reasonCategories: this.getReasonCategories(metadataApiResponse),
      reasonDescriptions: this.getReasonDescriptions(metadataApiResponse),
    }
  }

  private getReasonCategories(
    metadataApiResponse: ListCorrectionMetadataResp
  ): CorrectionsListMetadataReasonCategoriesModel[] {
    if (!metadataApiResponse?.reasonCategories) return [];
    return metadataApiResponse.reasonCategories.map((reasonCategory) => ({
      code: reasonCategory.categoryCd,
      description: reasonCategory.categoryDescription,
    }));
  }

  private getReasonDescriptions(
    metadataApiResponse: ListCorrectionMetadataResp
  ): CorrectionsListMetadataReasonDescriptionModel[] {
    if (!metadataApiResponse?.reasons) return [];
    return metadataApiResponse.reasons.map((reasonDescription) => ({
      code: reasonDescription.reasonCd,
      description: reasonDescription.reasonDescription,
      reasonCategoryCode: reasonDescription.categoryCd,
    }));
  }

  private getQueueData(metadataApiResponse: ListCorrectionMetadataResp): CorrectionsListMetadataQueueModel[] {
    if (!metadataApiResponse?.correctionQueues) return [];
    return metadataApiResponse.correctionQueues.map((queue) => ({
      queueName: queue.queueName,
      queueCorpCd: queue.queueCorpCd,
      queueDescription: queue.queueDescription,
    }));
  }
}
