import { RegexRules } from "./regex.utils";

export const validateApiTechnicalCodeError = (codeError: string): boolean => {
  return new RegExp(RegexRules.apiTechnicalCodeError).test(codeError);
};

export function boldingNumbers(input: string): string {
  const regex = RegexRules.onlyNumbersAndMoneySign;
  return input.replace(regex, '<b>$&</b>');
}
