import { NgModule } from '@angular/core';
import { BillClassPipe } from '@shared/pipes/common-fields/bill-class/bill-class.pipe';
import { ChargeCdPipe } from '@shared/pipes/common-fields/charge-cd/charge-cd.pipe';
import { CreditStatusPipe } from '@shared/pipes/common-fields/credit-status/credit-status.pipe';
import { DeliveryStatusPipe } from '@shared/pipes/common-fields/delivery-status/delivery-status.pipe';
import { OracleStatusPipe } from '@shared/pipes/common-fields/oracle-status/oracle-status.pipe';
import { PendingRequestPipe } from '@shared/pipes/common-fields/pending-request/pending-request.pipe';
import { RatedProcessPipe } from '@shared/pipes/common-fields/rated-process/rated-process.pipe';
import { CountryStatesPipe } from '@shared/pipes/country-states/country-states.pipe';
import { DateTimeXpoPipe } from '@shared/pipes/date-time-xpo/date-time-xpo.pipe';
import { DateXpoPipe } from '@shared/pipes/date-xpo/date-xpo.pipe';
import { ProNumberPipe } from '@shared/pipes/pro-number/pro-number.pipe';
import { YesNoIndicatorPipe } from '@shared/pipes/yes-no-indicator/yes-no-indicator.pipe';
import { NegativeNumberToParenthesesPipe } from './negative-number-to-parentheses/negative-number-to-parentheses.pipe';
import { CurrencyXpoPipe } from './currency-xpo/currency-xpo.pipe';
import { ChargesResponsablePipe } from './common-fields/charges-responsable/charges-responsable.pipe';
import { CommoditiesPiecesPipe } from './commodities-pieces/commodities-pieces.pipe';
import { SupplementalReferencePipe } from './supplemental-reference/supplemental-reference.pipe';
import { ShipmentRoutePipe } from './route/shipment-route.pipe';
import { WeightLinePipe } from './weight-line/weight-line.pipe';
import { PricingRulesetPipe } from './pricing-ruleset/pricing-ruleset.pipe';
import { CommoditiesClassPipe } from './commodities-class/commodities-class.pipe';
import { ProNumberModule } from '@shared/services/features/pro-number/pro-number.module';
import { HideWhenZeroPipe } from './hide-when-zero/hide-when-zero.pipe';

@NgModule({
  declarations: [		
    BillClassPipe,
    ChargeCdPipe,
    ChargesResponsablePipe,
    CountryStatesPipe,
    CreditStatusPipe,
    CurrencyXpoPipe,
    DateXpoPipe,
    DateTimeXpoPipe,
    DeliveryStatusPipe,
    NegativeNumberToParenthesesPipe,
    OracleStatusPipe,
    PendingRequestPipe,
    ProNumberPipe,
    CommoditiesPiecesPipe,
    CommoditiesClassPipe,
    SupplementalReferencePipe,
    ShipmentRoutePipe,
    RatedProcessPipe,
    YesNoIndicatorPipe,
    WeightLinePipe,
    PricingRulesetPipe,
    HideWhenZeroPipe
   ],
  exports: [
    BillClassPipe,
    ChargeCdPipe,
    ChargesResponsablePipe,
    CountryStatesPipe,
    CreditStatusPipe,
    CurrencyXpoPipe,
    DateXpoPipe,
    DateTimeXpoPipe,
    DeliveryStatusPipe,
    NegativeNumberToParenthesesPipe,
    OracleStatusPipe,
    PendingRequestPipe,
    ProNumberPipe,
    CommoditiesPiecesPipe,
    CommoditiesClassPipe,
    SupplementalReferencePipe,
    ShipmentRoutePipe,
    RatedProcessPipe,
    YesNoIndicatorPipe,
    WeightLinePipe,
    PricingRulesetPipe,
    HideWhenZeroPipe,
  ],
  providers: [
    CurrencyXpoPipe,
    DateXpoPipe,
    DateTimeXpoPipe,
    ProNumberPipe,
    CommoditiesPiecesPipe,
    CommoditiesClassPipe,
    SupplementalReferencePipe,
    ShipmentRoutePipe,
    YesNoIndicatorPipe
  ],
  imports: [
    ProNumberModule
  ]
})
export class PipesModule {}
