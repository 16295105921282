import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CorrectionsAuditorQueryParamsUrlEnum } from '@shared/enums/corrections/auditor-function/corrections-auditor-function-route.enum';
import { CorrectionsBillQueryParamsUrlEnum } from '@shared/enums/corrections/bill-details/corrections-bill-details-route.enum';
import { CorrectionsFormViewEnum } from '@shared/enums/corrections/corrections-form/corrections-form.enum';
import { CorrectionsPreviewRateQueryParamsUrlEnum } from '@shared/enums/corrections/preview-rate/corrections-preview-rate-details-route.enum';
import { CorrectionsAuditorQueryParamsUrl } from '@shared/models/corrections/auditor-function/corrections-auditor-route.model';
import { CorrectionsBillQueryParamsUrl } from '@shared/models/corrections/bill-search/corrections-bill-search-route.model';
import { CorrectionsRouterStateModel } from '@shared/models/corrections/corrections-router.model';
import { CorrectionsPreviewRatesQueryParamsUrl } from '@shared/models/corrections/preview-rates/corrections-preview-rates-route.model';
import { CorrectionsRoutes } from '@shared/routes/corrections-routes.constant';
import { Observable } from 'rxjs';
import { UrlsService } from '@shared/services/router-navigation/urls/urls.service';
import { RouterNavigationGoToHistoryDetailModel } from '@shared/models/shared/common-data/router-navigation.model';

@Injectable({
  providedIn: 'root'
})
export class RouterNavigationCorrectionsService {
  billDetailsSearchUrl = `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.search}`;
  historyCorrectionsUrl = `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.history}`;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlsService: UrlsService
  ) { }

  getBillQueryParams(): CorrectionsBillQueryParamsUrl {
    const mainQueryParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsBillQueryParamsUrlEnum.main
    ) as string;
    const pickupDate = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsBillQueryParamsUrlEnum.pickupDate
    ) as string;
    const requestId = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsBillQueryParamsUrlEnum.requestId
    ) as string;
    const correctionRequestInstIdParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsBillQueryParamsUrlEnum.correctionRequestInstId
    ) as string;
    const requestDateTimeParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsBillQueryParamsUrlEnum.requestDateTime
    ) as string;

    const completeDateParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsBillQueryParamsUrlEnum.completeDate
    ) as string;

    const requestDateTime = Number(requestDateTimeParam);
    const completeDate = Number(completeDateParam);
    const correctionRequestInstId = Number(correctionRequestInstIdParam);

    return {
      [CorrectionsBillQueryParamsUrlEnum.main]: mainQueryParam,
      [CorrectionsBillQueryParamsUrlEnum.pickupDate]: pickupDate,
      [CorrectionsBillQueryParamsUrlEnum.requestId]: requestId,
      [CorrectionsBillQueryParamsUrlEnum.correctionRequestInstId]: correctionRequestInstId,
      [CorrectionsBillQueryParamsUrlEnum.requestDateTime]: isNaN(requestDateTime) ? 0 : requestDateTime,
      [CorrectionsBillQueryParamsUrlEnum.completeDate]: isNaN(completeDate) ? 0 : completeDate,
    };
  }

  getPreviewRatesQueryParams(): CorrectionsPreviewRatesQueryParamsUrl {
    const proNbr = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsPreviewRateQueryParamsUrlEnum.proNbr
    ) as string;
    const formView = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsPreviewRateQueryParamsUrlEnum.formView
    ) as CorrectionsFormViewEnum;
    const requestIdParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsPreviewRateQueryParamsUrlEnum.requestId
    ) as string;
    const correctionRequestInstIdParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsPreviewRateQueryParamsUrlEnum.correctionRequestInstId
    ) as string;
    const requestDateTimeParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsPreviewRateQueryParamsUrlEnum.requestDateTime
    ) as string;
    const queueName = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsPreviewRateQueryParamsUrlEnum.queueName
    ) as string;

    const requestDateTime = Number(requestDateTimeParam);
    const requestId = Number(requestIdParam);
    const correctionRequestInstId = Number(correctionRequestInstIdParam);

    return {
      [CorrectionsPreviewRateQueryParamsUrlEnum.proNbr]: proNbr,
      [CorrectionsPreviewRateQueryParamsUrlEnum.queueName]: queueName,
      [CorrectionsPreviewRateQueryParamsUrlEnum.formView]: formView,
      [CorrectionsPreviewRateQueryParamsUrlEnum.requestId]: isNaN(requestId) ? 0 : requestId,
      [CorrectionsPreviewRateQueryParamsUrlEnum.correctionRequestInstId]: isNaN(correctionRequestInstId) ? 0 : correctionRequestInstId,
      [CorrectionsPreviewRateQueryParamsUrlEnum.requestDateTime]: isNaN(requestDateTime) ? 0 : requestDateTime,
    };
  }

  getAuditorQueryParams(): CorrectionsAuditorQueryParamsUrl {
    const correctionRequestInstId = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsAuditorQueryParamsUrlEnum.correctionRequestInstId
    ) as string;
    const proNbrQueryParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsAuditorQueryParamsUrlEnum.proNbr
    ) as string;
    const queueNameQueryParam = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsAuditorQueryParamsUrlEnum.queueName
    ) as string;
    const requestId = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsAuditorQueryParamsUrlEnum.requestId
    ) as string;
    const status = this.activatedRoute.snapshot.queryParamMap.get(
      CorrectionsAuditorQueryParamsUrlEnum.status
    ) as string;

    return {
      [CorrectionsAuditorQueryParamsUrlEnum.correctionRequestInstId]: correctionRequestInstId,
      [CorrectionsAuditorQueryParamsUrlEnum.proNbr]: proNbrQueryParam,
      [CorrectionsAuditorQueryParamsUrlEnum.queueName]: queueNameQueryParam,
      [CorrectionsAuditorQueryParamsUrlEnum.requestId]: requestId,
      [CorrectionsAuditorQueryParamsUrlEnum.status]: status,
    };
  }

  goToAuditForm(proNbr: string, queueName: string, correctionRequestInstId: number): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.auditorFunction.main}/${CorrectionsRoutes.auditorFunction.form}`
      ],
      {
        queryParams: {
          [CorrectionsAuditorQueryParamsUrlEnum.correctionRequestInstId]: correctionRequestInstId,
          [CorrectionsAuditorQueryParamsUrlEnum.proNbr]: proNbr,
          [CorrectionsAuditorQueryParamsUrlEnum.queueName]: queueName
        },
      }
    );
  }

  goToAuditInfoHistory(requestId: string): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.auditInfoHistory}`,
      ],
      { queryParams: { [CorrectionsBillQueryParamsUrlEnum.requestId]: requestId } as CorrectionsBillQueryParamsUrl }
    );
  }

  goToAuditInfoPending(requestId: string): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.auditInfoPending}`,
      ],
      { queryParams: { [CorrectionsBillQueryParamsUrlEnum.requestId]: requestId } as CorrectionsBillQueryParamsUrl }
    );
  }

  goToAuditInfoAuditorPending(queryParamsUrl: CorrectionsAuditorQueryParamsUrl): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.auditorFunction.main}/${CorrectionsRoutes.auditorFunction.auditInfoPending}`,
      ],
      {
        queryParams: {
          [CorrectionsAuditorQueryParamsUrlEnum.proNbr]: queryParamsUrl.proNbr,
          [CorrectionsAuditorQueryParamsUrlEnum.queueName]: queryParamsUrl.queueName,
          [CorrectionsAuditorQueryParamsUrlEnum.requestId]: queryParamsUrl.requestId,
          [CorrectionsAuditorQueryParamsUrlEnum.status]: queryParamsUrl.status,
        },
      }
    );
  }

  goToAuditorPending(queryParamsUrl: CorrectionsAuditorQueryParamsUrl): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.auditorFunction.main}/${CorrectionsRoutes.auditorFunction.pending}`,
      ],
      {
        queryParams: {
          [CorrectionsAuditorQueryParamsUrlEnum.proNbr]: queryParamsUrl.proNbr,
          [CorrectionsAuditorQueryParamsUrlEnum.queueName]: queryParamsUrl.queueName,
          [CorrectionsAuditorQueryParamsUrlEnum.status]: queryParamsUrl.status,
        } as CorrectionsAuditorQueryParamsUrl,
      }
    );
  }

  goToAuditorReassign(queryParamsUrl: CorrectionsAuditorQueryParamsUrl): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.auditorFunction.main}/${CorrectionsRoutes.auditorFunction.reassign}`,
      ],
      {
        queryParams: {
          [CorrectionsAuditorQueryParamsUrlEnum.proNbr]: queryParamsUrl.proNbr,
          [CorrectionsAuditorQueryParamsUrlEnum.queueName]: queryParamsUrl.queueName,
        } as CorrectionsAuditorQueryParamsUrl,
      }
    );
  }

  goToAuditorSummary(): void {
    this.router.navigate([
      `/${CorrectionsRoutes.main}/${CorrectionsRoutes.auditorFunction.main}/${CorrectionsRoutes.auditorFunction.summary}`,
    ]);
  }

  goToBillDetailsSearch(): void {
    this.router.navigate([this.billDetailsSearchUrl]);
  }

  goToBillDetailsContent(mainQueryParam: string, state?: CorrectionsRouterStateModel): void {
    this.router.navigate(
      [`/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.content}`],
      {
        queryParams: { [CorrectionsBillQueryParamsUrlEnum.main]: mainQueryParam } as CorrectionsBillQueryParamsUrl,
        state,
      }
    );
  }

  goToCorrectionsForm(mainQueryParam: string): void {
    this.router.navigate([`/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.form.main}`], {
      queryParams: { [CorrectionsBillQueryParamsUrlEnum.main]: mainQueryParam } as CorrectionsBillQueryParamsUrl,
    });
  }

  goToHistoryCorrections(mainQueryParam: string): void {
    const queryParams = this.urlsService.getHistoryQueryParams({ mainQueryParam, isOpenInNewWindow: false }) as CorrectionsBillQueryParamsUrl;
    const url = this.urlsService.getHistoryUrl();

    this.router.navigate([url], { queryParams });
  }

  goToHistoryCorrectionsInNewTab(mainQueryParam: string): void {
    const rootUrl = this.urlsService.getRootUrl();
    const url = this.urlsService.getHistoryUrl();
    const queryParams = this.urlsService.getHistoryQueryParams({ mainQueryParam, isOpenInNewWindow: true });
    const path = `${rootUrl}/${url}?${queryParams}`;

    window.open(path, '_blank');
  }

  goToPendingCorrections(mainQueryParam: string): void {
    const queryParams = this.urlsService.getPendingQueryParams({ mainQueryParam, isOpenInNewWindow: false }) as CorrectionsBillQueryParamsUrl;
    const url = this.urlsService.getPendingUrl();

    this.router.navigate([url], { queryParams });
  }

  goToPendingCorrectionsInNewTab(mainQueryParam: string): void {
    const rootUrl = this.urlsService.getRootUrl();
    const url = this.urlsService.getPendingUrl();
    const queryParams = this.urlsService.getPendingQueryParams({ mainQueryParam, isOpenInNewWindow: true });
    const path = `${rootUrl}/${url}?${queryParams}`;

    window.open(path, '_blank');
  }

  goToPaymentHistory(proNbr: string, pickupDate: string): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.paymentHistory}`,
      ],
      {
        queryParams: {
          [CorrectionsBillQueryParamsUrlEnum.main]: proNbr,
          [CorrectionsBillQueryParamsUrlEnum.pickupDate]: pickupDate,
        } as CorrectionsBillQueryParamsUrl,
      }
    );
  }

  isItTheMainScreen(): boolean {
    return this.router.url === this.billDetailsSearchUrl;
  }

  goToAutoRatePreviewCorrections(queryParamsUrl: CorrectionsPreviewRatesQueryParamsUrl, state?: CorrectionsRouterStateModel): void {
    const autoRatePreviewUrl = `${CorrectionsRoutes.form.previewRate.main}/${CorrectionsRoutes.form.previewRate.autoRatePreview}`;
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.form.main}/${autoRatePreviewUrl}`,
      ],
      {
        queryParams: {
          [CorrectionsPreviewRateQueryParamsUrlEnum.proNbr]: queryParamsUrl.proNbr,
          [CorrectionsPreviewRateQueryParamsUrlEnum.requestId]: queryParamsUrl.requestId?.toString(),
          [CorrectionsPreviewRateQueryParamsUrlEnum.correctionRequestInstId]: queryParamsUrl.correctionRequestInstId,
          [CorrectionsPreviewRateQueryParamsUrlEnum.queueName]: queryParamsUrl.queueName,
          [CorrectionsPreviewRateQueryParamsUrlEnum.formView]: queryParamsUrl.formView,
          [CorrectionsPreviewRateQueryParamsUrlEnum.requestDateTime]: queryParamsUrl.requestDateTime
        } as CorrectionsBillQueryParamsUrl,
        state
      }
    );
  }

  goToManualRatePreviewCorrections(queryParamsUrl: CorrectionsPreviewRatesQueryParamsUrl, state?: CorrectionsRouterStateModel): void {
    const manualRatePreviewUrl = `${CorrectionsRoutes.form.previewRate.main}/${CorrectionsRoutes.form.previewRate.manualRatePreview}`;
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.form.main}/${manualRatePreviewUrl}`,
      ],
      {
        queryParams: {
          [CorrectionsPreviewRateQueryParamsUrlEnum.proNbr]: queryParamsUrl.proNbr,
          [CorrectionsPreviewRateQueryParamsUrlEnum.requestId]: queryParamsUrl.requestId?.toString(),
          [CorrectionsPreviewRateQueryParamsUrlEnum.correctionRequestInstId]: queryParamsUrl.correctionRequestInstId,
          [CorrectionsPreviewRateQueryParamsUrlEnum.queueName]: queryParamsUrl.queueName,
          [CorrectionsPreviewRateQueryParamsUrlEnum.formView]: queryParamsUrl.formView,
          [CorrectionsPreviewRateQueryParamsUrlEnum.requestDateTime]: queryParamsUrl.requestDateTime
        } as CorrectionsBillQueryParamsUrl,
        state
      }
    );
  }

  goToManualRateFromAutoPreviewCorrections(mainQueryParam: string): void {
    const previewFromAutoUrl = `${CorrectionsRoutes.form.previewRate.main}/${CorrectionsRoutes.form.previewRate.manualRatePreviewFromAuto}`;
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.form.main}/${previewFromAutoUrl}`,
      ],
      {
        queryParams: {
          [CorrectionsPreviewRateQueryParamsUrlEnum.proNbr]: mainQueryParam,
        } as CorrectionsBillQueryParamsUrl,
      }
    );
  }

  goToHistoryDetailCorrections(params: RouterNavigationGoToHistoryDetailModel): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.history}/${CorrectionsRoutes.billDetail.detail}`,
      ],
      { queryParams: {
        [CorrectionsBillQueryParamsUrlEnum.main]: params.proNbr,
        [CorrectionsBillQueryParamsUrlEnum.correctionRequestInstId]: params.correctionRequestInstId,
        [CorrectionsBillQueryParamsUrlEnum.completeDate]: params.completeDate,
        [CorrectionsBillQueryParamsUrlEnum.pickupDate]: params.pickupDate,
      } as CorrectionsBillQueryParamsUrl }
    );
  }

  goToPendingRequestForm(mainQueryParam: string, requestDateTime: number, correctionRequestInstId: number): void {
    this.router.navigate(
      [
        `/${CorrectionsRoutes.main}/${CorrectionsRoutes.billDetail.main}/${CorrectionsRoutes.billDetail.pendingRequest}`,
      ],
      {
        queryParams: {
          [CorrectionsBillQueryParamsUrlEnum.main]: mainQueryParam,
          [CorrectionsBillQueryParamsUrlEnum.correctionRequestInstId]: correctionRequestInstId,
          [CorrectionsBillQueryParamsUrlEnum.requestDateTime]: requestDateTime
        } as CorrectionsBillQueryParamsUrl
      }
    );
  }

  queryParamsObservable(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }

  updateProNumberUrlValue(proNbr: string): void {
    const queryParams = { ...this.activatedRoute.snapshot.queryParams };
    if (!queryParams[CorrectionsBillQueryParamsUrlEnum.main]) return;

    queryParams[CorrectionsBillQueryParamsUrlEnum.main] = proNbr;
    this.router.navigate([], { queryParams });
  }
}
