import { Pipe, PipeTransform } from '@angular/core';
import { CommodityPackageCd } from '@xpo-ltl/sdk-common';
import { CommodityPackageCdValues } from '../../data-values/common/rating-commodity-packages.data';

@Pipe({
  name: 'ratingCommodityPackagesPipe'
})
export class RatingCommodityPackagesPipe implements PipeTransform {

  transform(value: CommodityPackageCd): string {
    const transformedValue = CommodityPackageCdValues[value];

    if (!transformedValue) {
      console.error('ratingCommodityPackagesPipe, value is not part of commodity package enum', value);
      return value;
    }

    return transformedValue;
  }

}
