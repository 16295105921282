import { Pipe, PipeTransform } from '@angular/core';
import { RatingRatePipePropertiesModel } from '../../../models/rating-rate-pipe.model';
import { RatingMinimumChargeEnum, RatingMinimumChargeValues } from '../../../shared.exports';

@Pipe({
  name: 'ratingRatePipe'
})
export class RatingRatePipe implements PipeTransform {
  specialRateNumberValue = `${RatingMinimumChargeEnum.value}`;
  specialRateValue = RatingMinimumChargeEnum.valueToDisplay;
  
  constructor() {}

  transform(value: string | number, rateProperties: RatingRatePipePropertiesModel = { displayOnlyWhenItIsMinimum: false, minimumChargeInd: false, hideWhenZero: false }): string {
    if (value == null) return '';  
    else if (value == 0) return '';
    const numberValue = rateProperties.displayOnlyWhenItIsMinimum ? '' : `${value}`.toUpperCase();

    return (RatingMinimumChargeValues.includes(`${value}`) || rateProperties.minimumChargeInd)
        ? this.specialRateValue as string
        : numberValue;
  }

  transformToValue(value: string | number): string | number {
    if (typeof value === 'number') return value;

    return value.toUpperCase() === this.specialRateValue
      ? this.specialRateNumberValue
      : value;
  }

}
