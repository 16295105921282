import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CorrectionsAccountsReceivableMapperService } from '@shared/mappers/corrections/accounts-receivable/corrections-accounts-receivable-mapper.service';
import { CorrectionsAssociatedBillToMapperService } from "@shared/mappers/corrections/associated-bill-to/corrections-associated-bill-to-mapper.service";
import { CorrectionsCorrectedRequestMapperService } from '@shared/mappers/corrections/corrected-request/corrections-corrected-request-mapper.service';
import { CorrectionsCreateRequestCommonMapperService } from '@shared/mappers/corrections/create-request/common/corrections-create-request-common-mapper.service';
import { CorrectionsCreateRequestMapperService } from '@shared/mappers/corrections/create-request/corrections-create-request-mapper.service';
import { CorrectionsCreateRequestTdcMapperService } from '@shared/mappers/corrections/create-request/tdc/corrections-create-request-tdc-mapper.service';
import { CorrectionsCreditStatusMapperService } from '@shared/mappers/corrections/credit-status/corrections-credit-status-mapper.service';
import { CorrectionsDetailMapperService } from '@shared/mappers/corrections/detail/corrections-detail-mapper.service';
import { CorrectionsListMetadataMapperService } from '@shared/mappers/corrections/list-metadata/corrections-list-metadata-mapper.service';
import { CorrectionsAutoRatesMetadataMapperService } from '@shared/mappers/corrections/preview-rates-metadata/auto-rates/corrections-auto-rates-metadata-mapper.service';
import { CorrectionsManualRatesMetadataMapperService } from '@shared/mappers/corrections/preview-rates-metadata/manual-rates/corrections-manual-rates-metadata-mapper.service';
import { CorrectionsAutoRatesRequestMapperService } from '@shared/mappers/corrections/preview-rates-request/auto-rates/corrections-auto-rates-request-mapper.service';
import { CorrectionsManualRatesRequestMapperService } from '@shared/mappers/corrections/preview-rates-request/manual-rates/corrections-manual-rates-request-mapper.service';
import { CorrectionsRequestAndHistoryMapperService } from '@shared/mappers/corrections/request-and-history/corrections-request-and-history-mapper.service';
import { CorrectionsRequestQueueSummariesMapperService } from '@shared/mappers/corrections/request-queue-summaries/corrections-request-queue-summaries-mapper.service';
import { AccountsReceivableDataApiService } from '@shared/services/apis/accounts-receivable/accounts-receivable-data-api.service';
import { CustomerDataApiService } from "@shared/services/apis/customer/customer-data-api.service";
import { DataServicesDataApiService } from '@shared/services/apis/data-services/data-services-data-api.service';
import { CorrectionsShipmentApiService } from '@shared/services/apis/shipment/correctons-shipment-api.service';
import { CorrectionsEffects } from './effects/corrections.effect';
import { CorrectionsHandler } from './handler/corrections.handler';
import { CorrectionsReducer } from './reducer/corrections.reducer';

import { CorrectionsCorrectedUpdateValuesService } from '@shared/mappers/corrections/corrected-request/update-values/corrections-corrected-update-values.service';
import { CorrectionsPreviewRatesCommonMetadataMapperService } from '@shared/mappers/corrections/preview-rates-metadata/common/corrections-preview-rates-common-metadata-mapper.service';
import { CorrectionsPreviewtoRequestMetadataMapperService } from '@shared/mappers/corrections/preview-rates-metadata/preview-to-request/corrections-preview-to-request-metadata-mapper.service';
import { CorrectionsPreviewRatesCommonRequestMapperService } from '@shared/mappers/corrections/preview-rates-request/common/corrections-preview-rates-request-common-mapper.service';
import { CorrectionsRequestUnlockUpdateStatusMapperService } from '@shared/mappers/corrections/request-lock-update-status/corrections-request-unlock-update-status-mapper.service';
import { CorrectionsRequestUpdateQueueMapperService } from '@shared/mappers/corrections/request-update-queue/corrections-request-update-queue-mapper.service';
import { ShipmentDetailMapperService } from '@shared/mappers/corrections/shipment-detail/shipment-detail-mapper.service';
import { ErrorCodesService } from '@shared/services/apis-errors/error-codes/error-codes.service';
import { ShipmentDetailApiService } from '@shared/services/apis/shipment/shipment-detail-api.service';
import { ProNumberModule } from '@shared/services/features/pro-number/pro-number.module';
import { ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { RatingLockTariffService } from '@xpo-ltl/rating';
@NgModule({
  imports: [
    StoreModule.forFeature(
      CorrectionsReducer.correctionsDetailFeatureKey,
      CorrectionsReducer.reducer
    ),
    EffectsModule.forFeature([CorrectionsEffects]),
    ProNumberModule
  ],
  providers: [
    AccountsReceivableDataApiService,
    CorrectionsHandler,
    CustomerDataApiService,
    CorrectionsShipmentApiService,
    ShipmentApiService,
    ShipmentDetailApiService,
    CorrectionsAccountsReceivableMapperService,
    CorrectionsAssociatedBillToMapperService,
    CorrectionsCorrectedRequestMapperService,
    CorrectionsCorrectedUpdateValuesService,
    CorrectionsCreateRequestCommonMapperService,
    CorrectionsPreviewRatesCommonMetadataMapperService,
    CorrectionsPreviewRatesCommonRequestMapperService,
    CorrectionsPreviewtoRequestMetadataMapperService,
    CorrectionsCreateRequestMapperService,
    CorrectionsCreateRequestTdcMapperService,
    CorrectionsCreditStatusMapperService,
    ShipmentDetailMapperService,
    CorrectionsDetailMapperService,
    CorrectionsListMetadataMapperService,
    CorrectionsAutoRatesMetadataMapperService,
    CorrectionsAutoRatesRequestMapperService,
    CorrectionsManualRatesMetadataMapperService,
    CorrectionsManualRatesRequestMapperService,
    CorrectionsRequestAndHistoryMapperService,
    CorrectionsRequestQueueSummariesMapperService,
    CorrectionsRequestUnlockUpdateStatusMapperService,
    CorrectionsRequestUpdateQueueMapperService,
    DataServicesDataApiService,
    RatingLockTariffService,
    ErrorCodesService,
  ]
})
export class CorrectionsReduxModule {}
