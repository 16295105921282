import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RatingInputRateComponent } from './components/form-inputs/input-rate/rating-input-rate.component';
import { RatingCommodityPackagesPipe } from './pipes/commodity-packages/rating-commodity-packages.pipe';
import { RatingRatePipe } from './pipes/numbers/rate/rating-rate.pipe';
import { RatingFilesHandlerService } from './services/files/files-handler/rating-files-handler.service';
import { RatingXpoClassPipe } from './shared.exports';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [
    RatingXpoClassPipe,
    RatingCommodityPackagesPipe,
    RatingInputRateComponent,
    RatingRatePipe,
  ],
  exports: [
    RatingXpoClassPipe,
    RatingCommodityPackagesPipe,
    RatingInputRateComponent,
    RatingRatePipe,
  ],
  providers: [
    RatingFilesHandlerService
  ]
})
export class RatingSharedModule { }
