export enum BillClassTextEnum {
  normalMvmt = 'NORM',
  masterShpmt = 'MSTR',
  masterSegment = 'MSEG',
  splitSegment = 'PTLT',
  partialSegment = 'PSEG',
  claimsOvrgRptgBill = 'OREP',
  generalClaimsBusShpmt = 'GCBZ',
  astrayFrtSegment = 'MOVR',
  coBusShpmt = 'COBZ',
  expedite = 'EXPD',
}
