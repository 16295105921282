export class RatingStatesModel {
    name: string;
    abbreviation: string;
}

export class RatingCountryStatesModel {
    [country: string]: {
        states: RatingStatesModel[];
    }
}
