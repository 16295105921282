import { Injectable } from '@angular/core';
import { UserHandler } from '@app-store/user/handler/user.handler';
import { OracleStatusEnum } from '@shared/enums/common-data/oracle-status.enum';
import { ScreenEnum } from '@shared/enums/common-data/screen.enum';
import { CorrectionsRateContentDetailsLabelsEnum } from '@shared/enums/corrections/corrections-common.enum';
import { CorrectionsDateTimeTypesEnum } from '@shared/enums/corrections/corrections-form/timeDeliveryWindow/corrections-time-delivery-window-form.enum';
import { CorrectionsWeightFormTypeEnum } from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';
import { AccessorialsMapperService } from '@shared/mappers/common/accessorials/utls/accessorials-mapper.service';
import { CommoditiesMapperService } from '@shared/mappers/common/commodities/utils/commodities-mapper.service';
import { CommonDateMapperService } from '@shared/mappers/common/common-date/common-date-mapper.service';
import { DeliveryStatusMapperService } from '@shared/mappers/common/deliveryStatus/delivery-status-mapper.service';
import { TdcCommonMapperService } from '@shared/mappers/common/tdc/tdc-common-mapper.service';
import {
  CorrectionsAccessorialRespModel,
  CorrectionsAdvanceScacRespModel,
  CorrectionsAmcAmountRespModel,
  CorrectionsAsWeightRespModel,
  CorrectionsAuditorRemarksRespModel,
  CorrectionsBeyondScacRespModel,
  CorrectionsBillStatusModel,
  CorrectionsBilltoRespModel,
  CorrectionsCashConsigneeRespModel,
  CorrectionsCashPaidByShipperRespModel,
  CorrectionsChargeCodeRespModel,
  CorrectionsCodAmountRespModel,
  CorrectionsCommoditiesRespModel,
  CorrectionsConsigneeRespModel,
  CorrectionsCustomInBondRespModel,
  CorrectionsDeficitWeightRespModel,
  CorrectionsDetailModel,
  CorrectionsDiscountAmountRespModel,
  CorrectionsGeneralInfoModel,
  CorrectionsGuaranteedRespModel,
  CorrectionsMeasuresRespModel,
  CorrectionsPricingAgreementRespModel,
  CorrectionsRequestInformationModel,
  CorrectionsShipperRespModel,
  CorrectionsShippingRemarksRespModel,
  CorrectionsSicRespModel,
  CorrectionsSupplementalReferencesRespModel,
  CorrectionsTariffRespModel,
  CorrectionsTimeDateCriticalRespModel,
  CorrectionsTotalRevenueRespModel,
  CorrectionsWeightLineRespModel
} from '@shared/models/corrections/corrections-detail.model';
import { PendingRequestStoreModel } from '@shared/models/shared/store/pending-request-store.model';
import { UserModel } from '@shared/models/shared/user.model';
import { DateUtils } from '@shared/utils/date/date-utils.service';
import { GetShipmentForPotentialCorrectionResp, ListCorrectionRequestsAndHistoryQuery } from '@xpo-ltl-2.0/sdk-billcorrection';
import { SupplementalReference } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable()
export class CorrectionsDetailMapperService {

  serviceName = 'CorrectionsDetailMapperService';

  constructor(
    private userHandler: UserHandler,
    private commonDateMapperService: CommonDateMapperService,
    private deliveryStatusMapperService: DeliveryStatusMapperService,
    private tdcCommonMapperService: TdcCommonMapperService,
    private accessorialsMapperService: AccessorialsMapperService,
    private commoditiesMapperService: CommoditiesMapperService
  ) { }

  getRequest(pendingRequestStore: PendingRequestStoreModel): ListCorrectionRequestsAndHistoryQuery {
    return pendingRequestStore.currentScreen === ScreenEnum.billHistoryDetail
      ? this.getRequestForHistory(pendingRequestStore)
      : this.getDefaultRequest(pendingRequestStore);
  }

  getMappedData(response: GetShipmentForPotentialCorrectionResp): Observable<CorrectionsDetailModel> {
    const detailFromApi = this.getDetailFromApi(response);
    return this.setRequesterData(detailFromApi.requestInformation).pipe(
      map(() => detailFromApi)
    );
  }

  getDetailFromApi(response: GetShipmentForPotentialCorrectionResp): CorrectionsDetailModel {
    return {
      proNbr: response.shipment.proNbr,
      parentProNbr: response.shipment.parentShipmentId?.proNumber,
      auditorRemarks: this.getAuditorRemarks(response),
      pickupDate: response.shipment.pickupDate,
      shipmentInstId: response.shipment.shipmentInstId,
      ctsRevenueAmount: response.shipment.ctsRevenueAmount,
      totalChargeAmount: response.shipment.totalChargeAmount,
      billingRemarks: response.shipment.billingRemarks,
      pricingBeforeAgreement: this.getPricingAgreement(response),
      accessorials: this.getAccessorials(response),
      advanceScac: this.getAdvanceScac(response),
      amcAmount: this.getAmcAmount(response),
      beyondScac: this.getBeyondScac(response),
      billTo: this.getBillto(response),
      billStatus: this.getBillStatus(response),
      cashCollectedConsignee: this.getCashConsigee(response),
      cashPaidByShipper: this.getCashPaidByShipper(response),
      chargeCode: this.getChageCode(response),
      codAmount: this.getCodAmount(response),
      commodities: this.getCommodities(response),
      consignee: this.getConsignee(response),
      customInBond: this.getCustomInBond(response),
      discountAmount: this.getDiscountAmount(response),
      generalInfo: this.getGeneralInfo(response),
      guaranteed: this.getGuaranteed(response),
      measures: this.getMeassures(response),
      requestInformation: this.getRequestInformation(response),
      supplementalReferences: this.getSupplementalReferences(response),
      shipper: this.getShipper(response),
      shippingRemarks: this.getShippingRemarks(response),
      sic: this.getSic(response),
      totalRevenue: this.getTotalRevenue(response),
      timeDateCritical: this.getTimeDateCritical(response),
      tariff: this.getTariff(response),
      weightLine: this.getWeightLine(response)
    };
  }

  private getPricingAgreement(response: GetShipmentForPotentialCorrectionResp): CorrectionsPricingAgreementRespModel {
    if (!response.shipment.pricingAgreement) { return new CorrectionsPricingAgreementRespModel(); }

    return {
      pricingRulesetNbr: response.shipment.pricingAgreement.prcRulesetNbr,
      prcAgreementInstId: response.shipment.pricingAgreement.prcAgreementInstId,
      pricingAgreementNbrTxt: response.shipment.pricingAgreement.prcAgreementNbrTxt,
    };
  }

  private getDateTime(dateTime: Date): number | undefined {
    if (!dateTime) return;

    try {
      return dateTime ? this.commonDateMapperService.getTimeStampNumberFromApi(dateTime) : undefined;
    } catch (error) {
      console.log(error, this.serviceName);
      return;
    }
  }

  private getBillStatus(response: GetShipmentForPotentialCorrectionResp): CorrectionsBillStatusModel {
    return {
      billClass: response.shipment.billClassCd,
      closedRequestsNbr: response.shipment.closedRequestsNbr,
      originTerminalSic: response.shipment.originTerminalSic,
      destinationTerminalSic: response.shipment.destinationTerminalSic,
      chargeCode: response.shipment.chargeToCd,
      // NOTE: lastCorrectionAppliedDate is a timestamp number but the api is returning it as a string
      lastCorrectionDate: response.shipment.lastCorrectionAppliedDate ? Number(response.shipment.lastCorrectionAppliedDate) : undefined,
      pendingRequestAvailable: response.shipment.pendingRequestsAvailableNbr,
      oracleStatus: this.getOracleStatus(response),
      pickupDate: response.shipment.pickupDate,
      proNbr: response.shipment.proNbr,
    };
  }

  private getGeneralInfo(response: GetShipmentForPotentialCorrectionResp): CorrectionsGeneralInfoModel {

    const deliveryDate = this.getDateTime(response.shipment.lastMovementDateTime);

    return {
      deliveryDate,
      deliverySic: response.shipment.lastMoveRptgSicCd,
      deliveryStatus: response.shipment.deliveryQualifierCd,
      expectedDeliveryDate: response.shipment.estimatedDeliveryDate,
      freezableInd: response.shipment.freezableInd,
      guaranteedInd: response.shipment.guaranteedInd,
      linealFeet: response.shipment.linealFootTotalNbr,
      motorizedPieces: response.shipment.motorizedPiecesCount,
      palletCount: response.shipment.totalPalletCount,
      rated: response.shipment.manualRatedInd,
    };
  }

  private setRequesterData(requestInfo: CorrectionsRequestInformationModel): Observable<UserModel | undefined> {
    return this.userHandler.getLoggedUser$.pipe(
      filter(user => !!user),
      tap(user => {
        const lastName = user?.lastName || '';
        const name = user?.name || '';
        requestInfo.requester = `${name} ${lastName}`.trim();
        requestInfo.ccEmailTo = user?.email ? [{ emailAddress: user?.email, isLoggedUserEmail: true }] : [];
        requestInfo.requesterUserId = user?.employeeId || '';
      }),
      take(1),
    );
  }

  private getRequestInformation(response: GetShipmentForPotentialCorrectionResp): CorrectionsRequestInformationModel {
    const deliveryDate = this.getDateTime(response.shipment.lastMovementDateTime);

    return {
      deliveryDate,
      expectedDelivery: response.shipment.estimatedDeliveryDate,
      pickupDate: response.shipment.pickupDate,
      isFinalDeliveryStatus: this.deliveryStatusMapperService.isFinalDeliveryStatus(response.shipment),
      requestDateTime: DateUtils.getCurrentDateTimeStamp(),
      requester: '',
      requestStatus: undefined as any, // This field is undefined because it is not part of the response
      auditorName: undefined as any, // This field is undefined because it is not part of the response
      ccEmailTo: [],
      requesterUserId: '',
      correctionQueue: undefined as any, // This field is undefined because it is not part of the response
      isDriverWaiting: undefined as any, // This field is undefined because it is not part of the response
      requesterComments: undefined as any // This field is undefined because it is not part of the response
    };
  }

  private getSupplementalReferences(response: GetShipmentForPotentialCorrectionResp): CorrectionsSupplementalReferencesRespModel {
    if (!response.shipment.supplementalReferences) return new CorrectionsSupplementalReferencesRespModel();
    return {
      poSupplement: this.filterSupplementBySequence(response.shipment.supplementalReferences, CorrectionsRateContentDetailsLabelsEnum.poNumber),
      snSupplement: this.filterSupplementBySequence(response.shipment.supplementalReferences, CorrectionsRateContentDetailsLabelsEnum.snNumber)
    };
  }

  private getShipper(response: GetShipmentForPotentialCorrectionResp): CorrectionsShipperRespModel {
    if (!response.shipment.shipper) { return new CorrectionsShipperRespModel(); }

    return {
      customerNumber: response.shipment.shipper.cisCustNbr,
      madCode: response.shipment.shipper.asMatchedMadCd,
      name1: response.shipment.shipper.name1,
      name2: response.shipment.shipper.name2,
      address: response.shipment.shipper.address,
      city: response.shipment.shipper.city,
      state: response.shipment.shipper.stateCd,
      zip: response.shipment.shipper.zip6,
      zipRest: response.shipment.shipper.zip4RestUs,
      country: response.shipment.shipper.countryCd,
      creditStatus: response.shipment.shipper.creditStatusCd
    };
  }

  private getConsignee(response: GetShipmentForPotentialCorrectionResp): CorrectionsConsigneeRespModel {
    if (!response.shipment.consignee) { return new CorrectionsConsigneeRespModel(); }

    return {
      customerNumber: response.shipment.consignee.cisCustNbr,
      madCode: response.shipment.consignee.asMatchedMadCd,
      name1: response.shipment.consignee.name1,
      name2: response.shipment.consignee.name2,
      address: response.shipment.consignee.address,
      city: response.shipment.consignee.city,
      state: response.shipment.consignee.stateCd,
      zip: response.shipment.consignee.zip6,
      zipRest: response.shipment.consignee.zip4RestUs,
      country: response.shipment.consignee.countryCd,
      creditStatus: response.shipment.consignee.creditStatusCd
    };
  }

  private getBillto(response: GetShipmentForPotentialCorrectionResp): CorrectionsBilltoRespModel {
    if (!response.shipment.billTo) { return new CorrectionsBilltoRespModel(); }

    return {
      customerNumber: response.shipment.billTo.cisCustNbr,
      madCode: response.shipment.billTo.asMatchedMadCd,
      name1: response.shipment.billTo.name1,
      name2: response.shipment.billTo.name2,
      address: response.shipment.billTo.address,
      city: response.shipment.billTo.city,
      state: response.shipment.billTo.stateCd,
      zip: response.shipment.billTo.zip6,
      zipRest: response.shipment.billTo.zip4RestUs,
      country: response.shipment.billTo.countryCd,
      chrgsResp: response.shipment.billTo.typeCd
    };
  }

  private getChageCode(response: GetShipmentForPotentialCorrectionResp): CorrectionsChargeCodeRespModel {
    return {
      chargeCode: response.shipment.chargeToCd,
      ppdText: response.shipment.prepaidLine?.description,
      ppdAmount: response.shipment.prepaidLine?.amount,
      ppdSequenceNbr: response.shipment.prepaidLine?.sequenceNbr,
      colText: response.shipment.collectLine?.description,
      colAmount: response.shipment.collectLine?.amount,
      colSequenceNbr: response.shipment.collectLine?.sequenceNbr,
    };
  }

  private getAdvanceScac(response: GetShipmentForPotentialCorrectionResp): CorrectionsAdvanceScacRespModel {
    return {
      scacCode: response.shipment.advanceCarrier?.carrierScacCd,
      date: response.shipment.advanceCarrier?.carrierPickupDate,
      proNumber: response.shipment.advanceCarrier?.carrierProNbr,
      advanceRevenue: response.shipment.advanceCarrier?.chargeAmount
    };
  }

  private getBeyondScac(response: GetShipmentForPotentialCorrectionResp): CorrectionsBeyondScacRespModel {
    return {
      scacCode: response.shipment.beyondCarrier?.carrierScacCd,
      fromTerminalSic: response.shipment.beyondCarrier?.fromTerminalSicCd,
      beyondRevenue: response.shipment.beyondCarrier?.chargeAmount
    };
  }

  private getTotalRevenue(response: GetShipmentForPotentialCorrectionResp): CorrectionsTotalRevenueRespModel {
    return {
      ctsRevenue: response.shipment.ctsRevenueAmount,
      totalRevenue: response.shipment.totalChargeAmount,
      totalWeight: response.shipment.totalWeightLbs
    };
  }

  private getCustomInBond(response: GetShipmentForPotentialCorrectionResp): CorrectionsCustomInBondRespModel {
    return {
      bondNumber: response.shipment.customsInBond?.bondNbr,
      city: response.shipment.customsInBond?.city,
      state: response.shipment.customsInBond?.state
    };
  }

  private getSic(response: GetShipmentForPotentialCorrectionResp): CorrectionsSicRespModel {
    return {
      origin: response.shipment.originTerminalSic,
      destination: response.shipment.destinationTerminalSic
    };
  }

  private getGuaranteed(response: GetShipmentForPotentialCorrectionResp): CorrectionsGuaranteedRespModel {
    return {
      guaranteedInd: response.shipment.guaranteedInd
    };
  }

  private getMeassures(response: GetShipmentForPotentialCorrectionResp): CorrectionsMeasuresRespModel {
    return {
      motorizedPieces: response.shipment.motorizedPiecesCount,
      palletCount: response.shipment.totalPalletCount,
      linealFeet: response.shipment.linealFootTotalNbr
    };
  }

  private getShippingRemarks(response: GetShipmentForPotentialCorrectionResp): CorrectionsShippingRemarksRespModel {
    return {
      deleteInd: undefined,
      remarks: response.shipment.shipmentRemarks
    };
  }

  private getCodAmount(response: GetShipmentForPotentialCorrectionResp): CorrectionsCodAmountRespModel {
    return {
      amount: response.shipment.codAmountLine?.amount,
      description: response.shipment.codAmountLine?.description,
      sequenceNbr: response.shipment.codAmountLine?.sequenceNbr
    };
  }

  private getCommodities(response: GetShipmentForPotentialCorrectionResp): CorrectionsCommoditiesRespModel[] {
    if (!response.shipment.commodities || response.shipment.commodities.length === 0) return [];
    return response.shipment.commodities.map(
      (commodity) => ({
        id: this.commoditiesMapperService.getId(commodity),
        sequenceNbr: commodity.sequenceNbr,
        pieces: commodity.piecesCount,
        pkg: commodity.packageCd,
        hazmat: commodity.hazardousMtInd,
        description: commodity.description,
        nmfc: commodity.nmfcItemCd,
        class: commodity.classType,
        weight: commodity.weightLbs,
        rate: commodity.tariffsRate,
        amount: commodity.amount,
        minimumChargeInd: commodity.minimumChargeInd
      })
    );
  }

  private getDeficitWeight(response: GetShipmentForPotentialCorrectionResp): CorrectionsDeficitWeightRespModel | undefined {
    if (!response.shipment.deficitWeightLine) return;

    return {
      type: response.shipment.deficitWeightLine.lineTypeCd,
      description: response.shipment.deficitWeightLine.description,
      quantity: response.shipment.deficitWeightLine.quantity,
      rate: response.shipment.deficitWeightLine.tariffsRate,
      amount: response.shipment.deficitWeightLine.amount,
      sequenceNbr: response.shipment.deficitWeightLine.sequenceNbr,
      minimumChargeInd: response.shipment.deficitWeightLine.minimumChargeInd
    };
  }

  private getAsWeight(response: GetShipmentForPotentialCorrectionResp): CorrectionsAsWeightRespModel | undefined {
    if (!response.shipment.asWeightLine) return;

    return {
      type: response.shipment.asWeightLine.lineTypeCd,
      description: response.shipment.asWeightLine.description,
      quantity: response.shipment.asWeightLine.quantity,
      rate: response.shipment.asWeightLine.tariffsRate,
      amount: response.shipment.asWeightLine.amount,
      sequenceNbr: response.shipment.asWeightLine.sequenceNbr,
      minimumChargeInd: response.shipment.asWeightLine.minimumChargeInd
    };
  }

  private getTariff(response: GetShipmentForPotentialCorrectionResp): CorrectionsTariffRespModel {
    return {
      code: response.shipment.ratingTariffsId,
      effectiveDate: response.shipment.tariffEffectiveDate
    };
  }

  private getWeightLine(response: GetShipmentForPotentialCorrectionResp): CorrectionsWeightLineRespModel {
    return {
      deleteInd: false,
      typeWeightLine: this.getWeightLineType(response),
      asWeight: this.getAsWeight(response),
      deficitWeight: this.getDeficitWeight(response)
    };
  }

  private getWeightLineType(response: GetShipmentForPotentialCorrectionResp): CorrectionsWeightFormTypeEnum | undefined {
    return response?.shipment?.asWeightLine
      ? CorrectionsWeightFormTypeEnum.asWeight : response?.shipment?.deficitWeightLine
        ? CorrectionsWeightFormTypeEnum.deficitWeight : undefined;
  }

  private getAmcAmount(response: GetShipmentForPotentialCorrectionResp): CorrectionsAmcAmountRespModel {
    return {
      amount: response.amcAmount
    };
  }

  private getDiscountAmount(response: GetShipmentForPotentialCorrectionResp): CorrectionsDiscountAmountRespModel {
    return {
      percentage: response.shipment.discountPercentage,
      description: response.shipment.discountAmountLine?.description,
      amount: response.shipment.discountAmountLine?.amount,
      sequenceNbr: response.shipment.discountAmountLine?.sequenceNbr
    };
  }

  private getAuditorRemarks(response: GetShipmentForPotentialCorrectionResp): CorrectionsAuditorRemarksRespModel {
    return {
      authorityRemarks: response?.shipment?.authorityRemarks
    };
  }

  private getAccessorials(response: GetShipmentForPotentialCorrectionResp): CorrectionsAccessorialRespModel[] {
    if (!response.shipment.accessorials || response.shipment.accessorials.length === 0) return [];

    return response.shipment.accessorials.map((acc) => ({
      id: this.accessorialsMapperService.getId(acc),
      sequenceNbr: acc.sequenceNbr,
      code: acc.accessorialCd,
      description: acc.accessorialDescription,
      rate: acc.tariffsRate,
      amount: acc.chargeAmount?.amt,
      minimumChargeInd: acc.minChargeInd
    }));
  }

  private getCashPaidByShipper(response: GetShipmentForPotentialCorrectionResp): CorrectionsCashPaidByShipperRespModel {
    return {
      amount: response.shipment.cashPrepaidLine?.amount,
      description: response.shipment.cashPrepaidLine?.description,
      sequenceNbr: response.shipment.cashPrepaidLine?.sequenceNbr,
    };
  }

  private getCashConsigee(response: GetShipmentForPotentialCorrectionResp): CorrectionsCashConsigneeRespModel {
    return {
      collectedInd: response.shipment.cashCollectedInd
    };
  }

  private getTimeDateCritical(response: GetShipmentForPotentialCorrectionResp): CorrectionsTimeDateCriticalRespModel {
    const timeDateCritical = response.shipment.timeDateCritical;
    return {
      shipmentInstId: timeDateCritical?.shipmentInstId,
      dateSelection: timeDateCritical?.tdcDateTypeCd,
      initialRangeDate: this.tdcCommonMapperService.isSelectedField(timeDateCritical?.tdcDateTypeCd, CorrectionsDateTimeTypesEnum.range, timeDateCritical?.tdcDate1),
      endRangeDate: this.tdcCommonMapperService.isSelectedField(timeDateCritical?.tdcDateTypeCd, CorrectionsDateTimeTypesEnum.range, timeDateCritical?.tdcDate2),
      initialOnDate: this.tdcCommonMapperService.isSelectedField(timeDateCritical?.tdcDateTypeCd, CorrectionsDateTimeTypesEnum.on, timeDateCritical?.tdcDate1),
      initialByDate: this.tdcCommonMapperService.isSelectedField(timeDateCritical?.tdcDateTypeCd, CorrectionsDateTimeTypesEnum.by, timeDateCritical?.tdcDate1),
      timeSelection: this.tdcCommonMapperService.getTimeSelectionValue(timeDateCritical?.tdcTimeTypeCd),
      initialRangeTime: this.tdcCommonMapperService.isSelectedField(timeDateCritical?.tdcTimeTypeCd, CorrectionsDateTimeTypesEnum.range, timeDateCritical?.tdcTime1),
      endRangeTime: this.tdcCommonMapperService.isSelectedField(timeDateCritical?.tdcTimeTypeCd, CorrectionsDateTimeTypesEnum.range, timeDateCritical?.tdcTime2),
      initialAfterTime: this.tdcCommonMapperService.isSelectedField(timeDateCritical?.tdcTimeTypeCd, CorrectionsDateTimeTypesEnum.after, timeDateCritical?.tdcTime1),
      initialBeforeTime: this.tdcCommonMapperService.isSelectedField(timeDateCritical?.tdcTimeTypeCd, CorrectionsDateTimeTypesEnum.before, timeDateCritical?.tdcTime1)
    };
  }

  private getOracleStatus(response: GetShipmentForPotentialCorrectionResp): OracleStatusEnum {
    if (response.oracleStatusCd && !Object.values(OracleStatusEnum).includes(response.oracleStatusCd as any)) {
      console.error(this.serviceName, `The oracle status value is not matched with the expected data`, `Api Value: ${response.oracleStatusCd}`, OracleStatusEnum);
    }
    return response.oracleStatusCd as OracleStatusEnum;
  }

  filterSupplementBySequence(references: SupplementalReference[], suppType: string): SupplementalReference | undefined {
    const minSeqByType: Record<string, SupplementalReference> = {};
    return references
      .filter(reference => reference.suppRefTypeCd.includes(suppType))
      .find(reference => {
        const { suppRefTypeCd, seq } = reference;
        const storedObj = minSeqByType[suppRefTypeCd];

        if (!storedObj || seq < storedObj.seq) {
          minSeqByType[suppRefTypeCd] = { ...reference };
          return true;
        }

        return false;
      });
  }


  // GET REQUEST *******************************************************************************************************************
  private getRequestForHistory(pendingRequestStore: PendingRequestStoreModel): ListCorrectionRequestsAndHistoryQuery {
    if (!pendingRequestStore.pickupDate) {
      const error = 'There is not a pickup date value, getRequestForHistory, corrections-detail-mapper';
      // console.error('There is not a pickup date value, getRequestForHistory, ')
      throw Error(error as string);
    }

    if (!pendingRequestStore.correctionRequestInstId) {
      const error = 'There is not a correctionRequestInstId date value, getRequestForHistory, corrections-detail-mapper';
      throw Error(error as string);
    }

    const queryParams = new ListCorrectionRequestsAndHistoryQuery();
    queryParams.proNbr = pendingRequestStore.proNbr;
    queryParams.pickupDate = pendingRequestStore.pickupDate;
    queryParams.correctionRequestInstId = pendingRequestStore.correctionRequestInstId;

    return queryParams;
  }

  private getDefaultRequest(pendingRequestStore: PendingRequestStoreModel): ListCorrectionRequestsAndHistoryQuery {
    const queryParams = new ListCorrectionRequestsAndHistoryQuery();
    queryParams.proNbr = pendingRequestStore.proNbr;

    return queryParams;
  }
  // *****************************************************************************************************************************************
}
