import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CorrectionsRoutes } from '@shared/routes/corrections-routes.constant';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@corrections/corrections.module').then(
        (module) => module.CorrectionsModule
    ),
      },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
