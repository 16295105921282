import { Pipe, PipeTransform } from '@angular/core';
import { CommodityClassCd } from '@xpo-ltl/sdk-common';
import { RatingXpoClassData } from '../../shared.exports';

@Pipe({
  name: 'ratingXpoClassPipe'
})
export class RatingXpoClassPipe implements PipeTransform {

  constructor() { }

  transform(classValue: CommodityClassCd): string | null {
    if (!classValue) return null;
    switch (classValue) {
      case CommodityClassCd.CLSS_100:
        return RatingXpoClassData[CommodityClassCd.CLSS_100];
      case CommodityClassCd.CLSS_110:
        return RatingXpoClassData[CommodityClassCd.CLSS_110];
      case CommodityClassCd.CLSS_125:
        return RatingXpoClassData[CommodityClassCd.CLSS_125];
      case CommodityClassCd.CLSS_150:
        return RatingXpoClassData[CommodityClassCd.CLSS_150];
      case CommodityClassCd.CLSS_175:
        return RatingXpoClassData[CommodityClassCd.CLSS_175];
      case CommodityClassCd.CLSS_200:
        return RatingXpoClassData[CommodityClassCd.CLSS_200];
      case CommodityClassCd.CLSS_250:
        return RatingXpoClassData[CommodityClassCd.CLSS_250];
      case CommodityClassCd.CLSS_300:
        return RatingXpoClassData[CommodityClassCd.CLSS_300];
      case CommodityClassCd.CLSS_350:
        return RatingXpoClassData[CommodityClassCd.CLSS_350];
      case CommodityClassCd.CLSS_400:
        return RatingXpoClassData[CommodityClassCd.CLSS_400];
      case CommodityClassCd.CLSS_500:
        return RatingXpoClassData[CommodityClassCd.CLSS_500];
      case CommodityClassCd.CLSS_50:
        return RatingXpoClassData[CommodityClassCd.CLSS_50];
      case CommodityClassCd.CLSS_55:
        return RatingXpoClassData[CommodityClassCd.CLSS_55];
      case CommodityClassCd.CLSS_60:
        return RatingXpoClassData[CommodityClassCd.CLSS_60];
      case CommodityClassCd.CLSS_65:
        return RatingXpoClassData[CommodityClassCd.CLSS_65];
      case CommodityClassCd.CLSS_70:
        return RatingXpoClassData[CommodityClassCd.CLSS_70];
      case CommodityClassCd.CLSS_775:
        return RatingXpoClassData[CommodityClassCd.CLSS_775];
      case CommodityClassCd.CLSS_85:
        return RatingXpoClassData[CommodityClassCd.CLSS_85];
      case CommodityClassCd.CLSS_925:
        return RatingXpoClassData[CommodityClassCd.CLSS_925];

      default:
        return classValue;
    }
  }

}
