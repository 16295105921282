import { Injectable } from '@angular/core';
import { RatingFileExtensions } from '../../../enums/Files.enum';

@Injectable()
export class RatingFilesHandlerService {
  constructor() {}

  base64ToArrayBuffer(base64: string): ArrayBufferLike {
    let binary = base64.replace(/\\n/g, '');
    binary = window.atob(base64);
    const len = binary.length;
    const bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
  }

  downloadFromBase64(fileData: string, name: string, fileType: RatingFileExtensions = RatingFileExtensions.csv): void {
    const blob = window.atob(fileData);
    const link = document.createElement('a');
    link.setAttribute('id', 'linkDownload');

    if (link.download !== undefined) {
      if (fileType === RatingFileExtensions.pdf) {
        link.href = `data:application/pdf;base64,${fileData}`;
      } else if (fileType === RatingFileExtensions.csv) {
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(blob);
      }
      link.target = '_blank';
      link.download = name;
      link.click();
    }
  }
}
