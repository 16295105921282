import { Injectable } from '@angular/core';
import { RatingMinimumChargeValues } from 'projects/rating-lib/src/lib/shared/shared.exports';

@Injectable({
  providedIn: 'root'
})
export class MinimumChargeService {

  constructor() { }

  getMinimumChargeInd(value: number | string | null): boolean {
    return RatingMinimumChargeValues.includes(`${value}`);
  }
}
